import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid } from "components/common"

import { BlogCard } from "src/sections/blog"

import { glassStyle } from "utils"

const PostsByAuthor = ({ blogPosts, teamMember }) => {
  const renderGrid = () => (
    <Grid
      col={3}
      gap={48}
      toEdge
      list={blogPosts}
      component={(post) => <BlogCard {...post} />}
    />
  )

  return (
    <StyledPostsByAuthor>
      <VerticalSection
        align="left"
        title={`Posts by ${teamMember.fullName}`}
        grid={renderGrid}
      />
    </StyledPostsByAuthor>
  )
}

const StyledPostsByAuthor = styled.div`
  --section-max-width: 1080px;

  .sectionBackground {
    ${glassStyle};
    border-radius: 0;
  }

  .gridWrap {
    ul {
      grid-gap: var(--sp-48);
    }
  }
`

export default PostsByAuthor
