import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import { CtaGetDemo } from "components/cta"

import { Layout, Seo, Nav, Link, TextGroup, Media } from "components/common"

import {
  useMeta,
  useRichText,
  richTextBody,
  device,
  useContentfulEdges,
} from "utils"

import { PostsByAuthor } from "src/sections/author"

import ArrowBack from "images/common/arrow-back.inline.svg"
import IconLink from "images/team/iconLink.inline.svg"

const TeamMember = ({
  data: { team_member, cta_get_demo, blogPostsByAuthor },
}) => {
  const { bio, photo, fullName, role, isAuthorOnly, website, metaDescription, excludeFromSearchIndex } =
    team_member

  const meta = useMeta({
    metaTitle: `${fullName} :: B A C K B O N E`,
    metaDescription,
    metaImage: photo,
  })
  const extraMeta = excludeFromSearchIndex ? [{name: 'robots', content: 'none'}] : [];

  const renderBio = useRichText({ text: bio })

  const blogPosts = useContentfulEdges(blogPostsByAuthor)

  const backTo = isAuthorOnly ? "/author/" : "/team"
  const backText = isAuthorOnly ? "Back to authors" : "Back to our team"

  return (
    <Layout>
      <Seo {...meta} meta={extraMeta} />
      <StyleTeamMember>
        <Nav />

        <div className="heroWrap">
          <Link className="back" to={backTo}>
            <ArrowBack />
            {backText}
          </Link>
          <TeamMemberSection>
            <div className="details">
              <Media className="photo" media={{ desktop: photo }} />
              <TextGroup
                className="fullName"
                title={fullName}
                subtitle={role}
                titleAs="h1"
              />
              {website && (
                <div>
                  <a
                    href={website}
                    target="_blank"
                    rel="noreferrer"
                    className="website"
                  >
                    <IconLink />
                    Website
                  </a>
                </div>
              )}
            </div>

            <div className="bio">{renderBio}</div>
          </TeamMemberSection>
        </div>

        {blogPosts.length >= 1 && (
          <div className="postsByAuthorWrap">
            <PostsByAuthor blogPosts={blogPosts} teamMember={team_member} />
          </div>
        )}

        <div className="bdContactUs">
          <CtaGetDemo {...cta_get_demo} />
        </div>
      </StyleTeamMember>
    </Layout>
  )
}

const StyleTeamMember = styled.div`
  padding-top: var(--sp-mobile-nav);
  @media ${device.laptop} {
    padding-top: var(--sp-nav);
  }

  .heroWrap {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 var(--sp-16);

    @media ${device.laptop} {
      padding: 0;
    }

    .back {
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: var(--sp-8);
      }
      font-size: 1.125rem;
    }
  }

  .bdContactUs {
    margin-top: var(--sp-section);
  }

  .fullName {
    --tg-title-color: var(--soil);
  }
  .bio {
    ${richTextBody};
  }
  .postsByAuthorWrap {
    margin-top: var(--sp-48);
    @media ${device.laptop} {
      margin-top: var(--sp-section);
    }
  }
`

const TeamMemberSection = styled.section`
  display: grid;
  grid-gap: var(--sp-32);
  margin-top: var(--sp-16);
  grid-template-columns: 1fr;

  @media ${device.laptop} {
    grid-template-columns: minmax(auto, 400px) 1fr;
  }

  .details {
    grid-template-columns: 1fr;
    grid-gap: var(--sp-16);

    .photo {
      border-radius: var(--card-radius);
      width: 100%;
      max-width: 400px;
      height: 400px;
      max-height: 400px;

      img {
        border-radius: var(--card-radius);
      }
    }
    .tgWrap {
      margin-top: var(--sp-16);

      .subtitle {
        --tg-subtitle-color: var(--dirt);
        font-weight: 500;
      }
    }

    .website {
      font-weight: 500;
      color: var(--spruce);
      display: inline-flex;
      align-items: center;
      margin-top: var(--sp-16);
      font-size: 1.25rem;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.8;
      }

      svg {
        margin-right: var(--sp-8);
      }
    }
  }
`

export default TeamMember

export const query = graphql`
  query TeamMemberSlug($slug: String!) {
    team_member: contentfulTeamMember(slug: { eq: $slug }) {
      ...TeamMember
    }
    blogPostsByAuthor: allContentfulBlogPost(
      sort: { order: DESC, fields: date }
      filter: {
        slug: { ne: "placeholder" }
        authors: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    cta_get_demo: contentfulSection(sectionId: { eq: "cta_get_demo" }) {
      ...Section
    }
  }
`
